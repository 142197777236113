// src/CoordinateSystem.js
import React, { useEffect, useState, useRef } from 'react';
import { uploadToR2 } from './uploadToR2'; // Importiere die uploadToR2 Funktion
import { firestore, addDoc } from "./firebase"; // Import Firestore functions
import { serverTimestamp, onSnapshot, query, orderBy, collection, doc, getDoc, updateDoc } from "firebase/firestore"; // Import Firestore functions
import { db } from './firebase'; // Importieren Sie Ihre Firebase-Instanz

const CoordinateSystem = ({ newModelImage, imageUrlRb }) => {
    const [images, setImages] = useState([]);
    const [dragging, setDragging] = useState(null);
    const [resizing, setResizing] = useState(null);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [resizeStart, setResizeStart] = useState({ x: 0, y: 0 });
    const [combinedImageUrl, setCombinedImageUrl] = useState(null); // Zustand für das kombinierte Bild
    const [combinedImageLink, setCombinedImageLink] = useState(null); // Zustand für das kombinierte Bild
    const [combinedCutOut, setCombinedCutOut] = useState(null); // Zustand für das kombinierte Bild
    const coordinateSystemRef = useRef(null); // Referenz für das Koordinatensystem
    const [originalDimensions, setOriginalDimensions] = useState({}); // Zustand für Originaldimensionen
    const [error, setError] = useState(null); // Zustand für Fehler
    const [userId, setUserId] = useState(null); // Zustand für die Benutzer-ID
    const [docRefId, setDocRefId] = useState(null); // Zustand für die Dokumentreferenz-ID
    const [maskUrl, setMaskUrl] = useState(null); // Zustand für das maskUrl-Bild
    const [combinedMaskUrl, setCombinedMaskUrl] = useState(null); // Zustand für das kombinierte Maskenbild
    const [processedImageUrl, setProcessedImageUrl] = useState(null); // Zustand für die processedImageUrl

    // Kombiniere processedImageUrl mit imageUrlRb
    const [finalImageUrl, setFinalImageUrl] = useState(null);

    // Zustand für den Dreh-Index
    const [rotating, setRotating] = useState(null); // Zustand für das Drehen
    const [rotationStart, setRotationStart] = useState(0); // Zustand für den Startwinkel

   //   useEffect(() => {
        // const combineImagesIfAvailable = async () => {
           // if (processedImageUrl && imageUrlRb) {
              //  const combinedImageUrl = await combineImages(processedImageUrl, imageUrlRb);
               // setFinalImageUrl(combinedImageUrl); // Setze die kombinierte URL in den Zustand
            //}
        //};

       // combineImagesIfAvailable(); // Rufe die Funktion auf, um die Bilder zu kombinieren
    //}, [processedImageUrl, imageUrlRb]); // Abhängigkeiten: Wenn processedImageUrl oder imageUrlRb sich ändern

    // Funktion zum Überprüfen, ob die maskUrl in Firestore vorhanden ist
    const checkMaskUrlExists = async (userId, docRefId) => {
        const docRef = doc(firestore, 'users', userId, 'images', docRefId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            return data.maskUrl; // Gibt die maskUrl zurück, wenn sie vorhanden ist
        }
        return null; // Gibt null zurück, wenn das Dokument nicht existiert
    };

    // Funktion zum Warten auf die maskUrl
    const waitForMaskUrl = async (userId, docRefId) => {
        let maskUrl = null;
        while (!maskUrl) {
            maskUrl = await checkMaskUrlExists(userId, docRefId);
            if (!maskUrl) {
                console.log('Waiting for maskUrl to be available...');
                await new Promise(resolve => setTimeout(resolve, 2000)); // Warte 2 Sekunden, bevor du erneut überprüfst
            }
        }
        return maskUrl; // Gibt die maskUrl zurück, wenn sie verfügbar ist
    };

    // Funktion zum Überprüfen, ob die processedImageUrl in Firestore vorhanden ist
    const checkProcessedImageUrlExists = async (userId, docRefId) => {
        const docRef = doc(firestore, 'users', userId, 'images', docRefId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            return data.processedImageUrl; // Gibt die processedImageUrl zurück, wenn sie vorhanden ist
        }
        return null; // Gibt null zurück, wenn das Dokument nicht existiert
    };

    // Funktion zum Warten auf die processedImageUrl
    const waitForProcessedImageUrl = async (userId, docRefId) => {
        let processedImageUrl = null;
        while (!processedImageUrl) {
            processedImageUrl = await checkProcessedImageUrlExists(userId, docRefId);
            if (!processedImageUrl) {
                console.log('Waiting for processedImageUrl to be available...');
                await new Promise(resolve => setTimeout(resolve, 2000)); // Warte 2 Sekunden, bevor du erneut überprüfst
            }
        }
        return processedImageUrl; // Gibt die processedImageUrl zurück, wenn sie verfügbar ist
    };

    // useEffect, um die URL auszulesen, die userId und docRefId zu setzen und das imageUrlRb in das images-Array zu laden
    useEffect(() => {
        const url = window.location.href; // Aktuelle URL abrufen
        console.log('Current URL:', url); // Debugging-Log
        const urlParts = url.split('/'); // URL in Teile aufteilen
        console.log('URL Parts:', urlParts); // Debugging-Log

        // Überprüfen, ob die URL die erwartete Struktur hat
        if (urlParts.length >= 7) { // Hier auf 7 Teile prüfen
            const userIdFromUrl = urlParts[5]; // userId ist der fünfte Teil
            const docRefIdFromUrl = urlParts[7]; // docRefId ist der siebte Teil

            setUserId(userIdFromUrl); // Setze die Benutzer-ID
            setDocRefId(docRefIdFromUrl); // Setze die Dokumentreferenz-ID

            console.log('User ID:', userIdFromUrl);
            console.log('Document Reference ID:', docRefIdFromUrl);

            // Funktion zum Überprüfen, ob die maskUrl vorhanden ist
            const checkAndLoadImage = async () => {
                const maskUrl = await checkMaskUrlExists(userIdFromUrl, docRefIdFromUrl);
                if (maskUrl) {
                    // Wenn maskUrl vorhanden ist, lade imageUrlRb
                    if (imageUrlRb) {
                        const img = new Image();
                        img.crossOrigin = 'Anonymous'; // Setze das crossOrigin-Attribut
                        img.src = imageUrlRb; // Setze die Bildquelle

                        img.onload = () => {
                            console.log(`Loaded imageUrlRb: ${imageUrlRb} with dimensions: ${img.width}x${img.height}`);
                            const aspectRatio = img.width / img.height; // Berechne das Seitenverhältnis
                            const scaledHeight = 400; // Höhe des Koordinatensystems
                            const scaledWidth = scaledHeight * aspectRatio; // Berechne die Breite basierend auf der Höhe

                            // Speichere die Originalauflösung mit der URL als Schlüssel
                            setOriginalDimensions(prev => ({
                                ...prev,
                                [imageUrlRb]: { width: img.width, height: img.height }, // Speichere die Originalgröße
                            }));

                            // Setze die Originalbreite und -höhe, wenn das Bild geladen ist
                            setImages(prevImages => {
                                const imageIndex = prevImages.findIndex(image => image.url === imageUrlRb);
                                if (imageIndex === -1) {
                                    return [
                                        ...prevImages,
                                        { url: imageUrlRb, x: 150, y: 150, width: scaledWidth, height: scaledHeight }, // Skaliert
                                    ];
                                }
                                return prevImages; // Wenn es bereits vorhanden ist, nichts ändern
                            });
                        };

                        img.onerror = () => {
                            console.error(`Failed to load imageUrlRb: ${imageUrlRb}`);
                        };
                    }
                } else {
                    console.log('maskUrl is not available yet. Retrying...');
                    setTimeout(checkAndLoadImage, 2000); // Versuche es nach 2 Sekunden erneut
                }
            };

            checkAndLoadImage(); // Überprüfe und lade das Bild

            // Überprüfe die processedImageUrl, wenn die Vorhersage erfolgreich ist
            const fetchProcessedImageUrl = async () => {
                const processedImageUrl = await checkProcessedImageUrlExists(userIdFromUrl, docRefIdFromUrl);
                if (processedImageUrl) {
                    setProcessedImageUrl(processedImageUrl); // Setze die URL in den Zustand
                    console.log('Processed Image URL is now available:', processedImageUrl);
                }
            };

            fetchProcessedImageUrl(); // Rufe die Funktion auf, um die URL abzurufen
        } else {
            console.error('URL does not have the expected structure.');
        }
    }, [imageUrlRb, userId, docRefId]); // Abhängigkeit: Wenn imageUrlRb, userId oder docRefId sich ändern

    // useEffect, um das newModelImage in das images-Array zu laden oder zu ersetzen
    useEffect(() => {
        if (newModelImage) {
            const img = new Image();
            img.src = newModelImage; // Bildquelle setzen

            img.onload = () => {
                console.log(`Loaded newModelImage: ${newModelImage} with dimensions: ${img.width}x${img.height}`);
                const aspectRatio = img.width / img.height; // Berechne das Seitenverhältnis
                const scaledHeight = 400; // Höhe des Koordinatensystems
                const scaledWidth = scaledHeight * aspectRatio; // Berechne die Breite basierend auf der Höhe

                // Speichere die Originalauflösung mit der URL als Schlüssel
                setOriginalDimensions(prev => ({
                    ...prev,
                    [newModelImage]: { width: img.width, height: img.height }, // Speichere die Originalgröße
                }));

                // Überprüfen, ob das Bild bereits im Array vorhanden ist
                const imageIndex = images.findIndex(image => image.url === newModelImage);
                if (imageIndex !== -1) {
                    // Wenn das Bild bereits vorhanden ist, aktualisiere nur die Position
                    setImages(prevImages => {
                        const updatedImages = [...prevImages];
                        updatedImages[imageIndex] = {
                            ...updatedImages[imageIndex],
                            x: 50, // Neue Position, wenn nötig
                            y: 50, // Neue Position, wenn nötig
                        };
                        return updatedImages;
                    });
                } else {
                    // Wenn das Bild nicht vorhanden ist, füge es mit den skalierten Abmessungen hinzu
                    setImages((prevImages) => {
                        const updatedImages = prevImages.filter(image => image.url === imageUrlRb);
                        return [
                            { url: newModelImage, x: 50, y: 50, width: scaledWidth, height: scaledHeight }, // Skaliert
                            ...updatedImages, // Behalte nur das Bild mit imageUrlRb
                        ];
                    });
                }
            };

            img.onerror = () => {
                console.error(`Failed to load newModelImage: ${newModelImage}`);
            };
        }
    }, [newModelImage]);

    useEffect(() => {
        const fetchMaskUrl = async () => {
            if (userId && docRefId) {
                const docRef = doc(firestore, 'users', userId, 'images', docRefId);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    const maskUrl = data.maskUrl; // Angenommen, maskUrl ist der Schlüssel in Firestore
                    setMaskUrl(maskUrl); // Setze den maskUrl in den Zustand
                } else {
                    console.error('No such document!');
                }
            }
        };

        fetchMaskUrl();
    }, [userId, docRefId]); // Abhängigkeiten

    const handleMouseDown = (index, e) => {
        setDragging(index);
        setOffset({ x: e.clientX - images[index].x, y: e.clientY - images[index].y });
    };

    const handleMouseUp = () => {
        setDragging(null);
        setResizing(null);
        setRotating(null); // Setze den Zustand für das Drehen zurück
    };

    const handleMouseMove = (e) => {
        if (dragging !== null) {
            const newImages = [...images];
            if (dragging >= 0 && dragging < newImages.length) {
                newImages[dragging].x = e.clientX - offset.x; // Aktualisiere die x-Position
                newImages[dragging].y = e.clientY - offset.y; // Aktualisiere die y-Position
                setImages(newImages);
            }
        } else if (resizing !== null) {
            const newImages = [...images];
            if (resizing >= 0 && resizing < newImages.length) {
                const deltaX = e.clientX - resizeStart.x; // Berechne die Änderung in der X-Richtung
                const deltaY = e.clientY - resizeStart.y; // Berechne die Änderung in der Y-Richtung

                const aspectRatio = newImages[resizing].width / newImages[resizing].height;
                newImages[resizing].width = Math.max(newImages[resizing].width + deltaX, 20);
                newImages[resizing].height = Math.max(newImages[resizing].width / aspectRatio, 20);

                setImages(newImages);
                setResizeStart({ x: e.clientX, y: e.clientY }); // Aktualisiere die Startposition
            }
        } else if (rotating !== null) {
            const rect = coordinateSystemRef.current.getBoundingClientRect();
            const centerX = images[rotating].x + images[rotating].width / 2; // Mittelpunkt des Bildes
            const centerY = images[rotating].y + images[rotating].height / 2; // Mittelpunkt des Bildes
            const angle = Math.atan2(e.clientY - centerY, e.clientX - centerX); // Berechne den aktuellen Winkel
            const rotationDelta = angle - rotationStart; // Berechne die Änderung des Winkels
            const newRotation = (images[rotating].rotation || 0) + rotationDelta; // Aktualisiere die Rotation
            setImages(prevImages => {
                const newImages = [...prevImages];
                newImages[rotating].rotation = newRotation; // Setze die neue Rotation
                return newImages;
            });
            setRotationStart(angle); // Setze den neuen Startwinkel
        }
    };

    const handleResizeMouseDown = (index, e) => {
        e.stopPropagation(); // Verhindert, dass das MouseDown-Ereignis das Bild zieht
        setResizing(index); // Setze den Index des Bildes, das resized wird
        setResizeStart({ x: e.clientX, y: e.clientY }); // Setze die Startposition für das Resize
    };

    const handleRotateMouseDown = (index, e) => {
        e.stopPropagation(); // Verhindert, dass das MouseDown-Ereignis das Bild zieht
        // Nur die Drehfunktion für imageUrlRb aktivieren
        if (images[index].url === imageUrlRb) {
            setRotating(index); // Setze den Index des Bildes, das gedreht wird
            const rect = coordinateSystemRef.current.getBoundingClientRect();
            const centerX = images[index].x + images[index].width / 2; // Mittelpunkt des Bildes
            const centerY = images[index].y + images[index].height / 2; // Mittelpunkt des Bildes
            const angle = Math.atan2(e.clientY - centerY, e.clientX - centerX); // Berechne den Startwinkel
            setRotationStart(angle); // Setze den Startwinkel
        }
    };

    // Funktion zum Aktualisieren des Firestore-Dokuments
    const updateImageInFirestore = async (userId, docRefId, combinedImageLink, combinedMaskUrl, combinedCutOutWidth, combinedCutOutHeight) => {
        try {
            if (!userId || !docRefId) {
                console.error('userId or docRefId is undefined:', { userId, docRefId });
                return; // Beende die Funktion, wenn userId oder docRefId undefined ist
            }

            const imageRef = doc(firestore, 'users', userId, 'images', docRefId); // Verwende die Benutzer-ID und Dokumentreferenz-ID
            const docSnapshot = await getDoc(imageRef); // Überprüfe, ob das Dokument existiert

            if (docSnapshot.exists()) {
                await updateDoc(imageRef, {
                    combinedImageLink: combinedImageLink, // Speichere die combinedImageUrl
                    combinedMaskUrl: combinedMaskUrl, // Speichere die combinedMaskUrl
                    combinedCutOutWidth: combinedCutOutWidth, // Speichere die Breite als combinedCutOutWidth
                    combinedCutOutHeight: combinedCutOutHeight // Speichere die Höhe als combinedCutOutHeight
                });
                console.log(`Updated Firestore document for user ${userId} and image ${docRefId} with combinedImageUrl: ${combinedImageLink}`);
            } else {
                console.log(`Document does not exist for user ${userId} and image ${docRefId}.`);
            }
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };

    // Funktion zum Speichern der Bildposition und -größe in Firestore
    const saveImagePositionAndSizeToFirestore = async (userId, docRefId, drawX, drawY, drawWidth, drawHeight, modelWidth, modelHeight, rotation) => {
        const imageRef = doc(firestore, 'users', userId, 'images', docRefId);
        try {
            await updateDoc(imageRef, {
                imagePositionX: drawX,
                imagePositionY: drawY,
                imageWidthX: drawWidth,
                imageHeightY: drawHeight,
                modelWidthX: modelWidth,
                modelHeightY: modelHeight,
                rotation: rotation // Speichere die Rotation
            });
            console.log(`Image position and size saved to Firestore: Position: (${drawX}, ${drawY}), Size: (${drawWidth}, ${drawHeight}), Model Size: (${modelWidth}, ${modelHeight}), Rotation: ${rotation}`);
        } catch (error) {
            console.error('Error saving image position and size to Firestore:', error);
        }
    };

    // Funktion zum Kombinieren und Hochladen des Model- und Image- Bildes
    const combineAndUploadImages = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Kombiniere das newModelImage
        const newImage = images.find(image => image.url === newModelImage);
        let combinedImageLink = null; // Variable zum Speichern des kombinierten Bildlinks
        let combinedMaskLink = null; // Variable zum Speichern des Maskenbildlinks

        if (newImage) {
            // Berechne die Größen
            const modelImageSizeX = newImage.width; // Breite des newModelImage
            const modelImageSizeY = newImage.height; // Höhe des newModelImage

            const imageRbPosition = images.find(image => image.url === imageUrlRb);
            const imageSizeX = imageRbPosition ? imageRbPosition.width : 0; // Breite des imageUrlRb
            const imageSizeY = imageRbPosition ? imageRbPosition.height : 0; // Höhe des imageUrlRb

            // Berechne die Skalierungsfaktoren
            const originalWidth = originalDimensions[newModelImage]?.width || 1; // Originalbreite von newModelImage
            const originalHeight = originalDimensions[newModelImage]?.height || 1; // Originalhöhe von newModelImage
            const scaleX = 2 * newImage.width / originalWidth; // Berechne den Skalierungsfaktor X
            const scaleY = 2 * newImage.height / originalHeight; // Berechne den Skalierungsfaktor Y

            // Berechne die neue Größe basierend auf den Skalierungsfaktoren
            const newWidth = newImage.width / scaleX; // Teile die Breite durch den Skalierungsfaktor
            const newHeight = newImage.height / scaleY; // Teile die Höhe durch den Skalierungsfaktor

            canvas.width = newWidth; // Setze die Canvas-Breite
            canvas.height = newHeight; // Setze die Canvas-Höhe

            // Zeichne jedes Bild auf das Canvas mit den entsprechenden Positionen und Größen
            for (const image of images) {
                const img = new Image();
                img.crossOrigin = 'Anonymous'; // Setze das crossOrigin-Attribut
                img.src = image.url;

                await new Promise((resolve, reject) => {
                    img.onload = async () => {
                        // Berechne die Position und Größe ohne devicePixelRatio
                        const drawX = (image.x - newImage.x) / scaleX; // Berechne die Position ohne devicePixelRatio
                        const drawY = (image.y - newImage.y) / scaleY; // Berechne die Position ohne devicePixelRatio
                        const drawWidth = image.width / scaleX; // Berechne die Breite ohne devicePixelRatio
                        const drawHeight = image.height / scaleY; // Berechne die Höhe ohne devicePixelRatio

                        // Protokolliere die Position und Größe ohne devicePixelRatio
                        console.log(`Drawing Image at Position: (X: ${drawX}, Y: ${drawY}), Size: (Width: ${drawWidth}, Height: ${drawHeight})`);

                        // Berechne die Skalierung und Verschiebung im Verhältnis zum newImage
                        const scaleFactorX = drawWidth / newImage.width;
                        const scaleFactorY = drawHeight / newImage.height;
                        const offsetX = drawX - newImage.x;
                        const offsetY = drawY - newImage.y;

                        // Ausgabe der Skalierung und Verschiebung
                        console.log(`Scale Factor: (X: ${scaleFactorX}, Y: ${scaleFactorY}), Offset: (X: ${offsetX}, Y: ${offsetY})`);

                        // Setze den Ursprung der Transformation auf die Mitte des Bildes
                        ctx.save(); // Speichere den aktuellen Zustand des Kontextes
                        ctx.translate(drawX + drawWidth / 2, drawY + drawHeight / 2); // Verschiebe den Ursprung
                        ctx.rotate((image.rotation || 0)); // Drehe das Bild
                        ctx.drawImage(img, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight); // Zeichne das Bild relativ zum neuen Ursprung
                        ctx.restore(); // Stelle den vorherigen Zustand des Kontextes wieder her

                        // Verwende die Originalgröße von newModelImage für modelWidthX und modelHeightY
                        const modelWidthX = originalDimensions[newModelImage]?.width || 0; // Originalbreite
                        const modelHeightY = originalDimensions[newModelImage]?.height || 0; // Originalhöhe

                        // Speichere die Position und Größe in Firestore
                        await saveImagePositionAndSizeToFirestore(userId, docRefId, drawX, drawY, drawWidth, drawHeight, modelWidthX, modelHeightY, image.rotation || 0);

                        resolve();
                    };
                    img.onerror = (error) => {
                        console.error('Error loading image:', error);
                        reject(error); // Stelle sicher, dass die Promise abgelehnt wird
                    };
                });
            }

            const combinedImageUrl = canvas.toDataURL('image/png');
            setCombinedImageUrl(combinedImageUrl);

            // Hochladen des kombinierten Bildes an Cloudflare R2
            const blob = await (await fetch(combinedImageUrl)).blob();
            try {
                combinedImageLink = await uploadToR2(blob); // Hochladen an Cloudflare R2
                setCombinedImageUrl(combinedImageUrl); // Setze die combinedImageUrl
                console.log('Uploaded Combined Image URL:', combinedImageLink);

                // Ausgabe der Position, der skalierte Größe und der Rotation des model und des Image Bildes
                const modelImagePosition = images.find(image => image.url === newModelImage);
                const imageRbPosition = images.find(image => image.url === imageUrlRb);

                console.log(`Model Image Position: (X: ${modelImagePosition.x}, Y: ${modelImagePosition.y}), Size: (Width: ${modelImagePosition.width}, Height: ${modelImagePosition.height}), Rotation: ${modelImagePosition.rotation || 0}`);
                console.log(`Image URL Rb Position: (X: ${imageRbPosition.x}, Y: ${imageRbPosition.y}), Size: (Width: ${imageRbPosition.width}, Height: ${imageRbPosition.height}), Rotation: ${imageRbPosition.rotation || 0}`);

            } catch (error) {
                console.error('Error uploading combined image to Cloudflare:', error);
                setError('Failed to upload combined image.'); // Benutzerbenachrichtigung
            }

            // Rufe die Funktion zum Kombinieren der Maskenbilder auf
            combinedMaskLink = await combineMaskAndUploadImages(); // Stelle sicher, dass diese Funktion aufgerufen wird

            // Speichere die combinedImageUrl und combinedMaskUrl in Firestore, wenn beide Links vorhanden sind
            if (combinedImageLink && combinedMaskLink) {
                await updateImageInFirestore(userId, docRefId, combinedImageLink, combinedMaskLink, modelImageSizeX, modelImageSizeY); // Speichere die URLs und Größen
                console.log('Combined Image Link saved successfully.');
            }
        }
    };

    // Funktion zum Kombinieren von Maske und weißem Rechteck Hochladen der Bilder
    const combineMaskAndUploadImages = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Kombiniere das newModelImage
        const newImage = images.find(image => image.url === newModelImage);
        const cutOutImage = images.find(image => image.url === imageUrlRb); // Finde das CutOut-Bild

        if (newImage) {
            // Warten auf die maskUrl
            const maskUrl = await waitForMaskUrl(userId, docRefId); // Warten, bis die maskUrl verfügbar ist

            // Berechne die Skalierungsfaktoren
            const originalWidth = originalDimensions[newModelImage]?.width || 1; // Originalbreite von newModelImage
            const originalHeight = originalDimensions[newModelImage]?.height || 1; // Originalhöhe von newModelImage
            const scaleX = 2 * newImage.width / originalWidth; // Berechne den Skalierungsfaktor X
            const scaleY = 2 * newImage.height / originalHeight; // Berechne den Skalierungsfaktor Y

            // Berechne die neue Größe basierend auf den Skalierungsfaktoren
            const newWidth = newImage.width / scaleX; // Teile die Breite durch den Skalierungsfaktor
            const newHeight = newImage.height / scaleY; // Teile die Höhe durch den Skalierungsfaktor

            canvas.width = newWidth; // Setze die Canvas-Breite
            canvas.height = newHeight; // Setze die Canvas-Höhe

            // Zeichne das newModelImage auf das Canvas
            await new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.src = newImage.url;

                img.onload = () => {
                    ctx.drawImage(img, 0, 0, newWidth, newHeight); // Zeichne das newModelImage
                    resolve();
                };
                img.onerror = reject;
            });

            // Zeichne das weiße Rechteck in der Größe des combinedImageUrl
            const combinedImageUrl = canvas.toDataURL('image/png');
            const combinedImage = new Image();
            combinedImage.src = combinedImageUrl;

            await new Promise((resolve, reject) => {
                combinedImage.onload = () => {
                    ctx.fillStyle = 'white'; // Setze die Füllfarbe auf weiß
                    ctx.fillRect(0, 0, combinedImage.width, combinedImage.height); // Zeichne das Rechteck in der Größe des kombinierten Bildes
                    resolve();
                };
                combinedImage.onerror = reject;
            });

            // Zeichne das maskUrl-Bild an der Position und Größe von imageUrlRb
            if (cutOutImage && maskUrl) {
                const maskImg = new Image();
                maskImg.crossOrigin = 'Anonymous';
                maskImg.src = maskUrl; // Verwende maskUrl

                await new Promise((resolve, reject) => {
                    maskImg.onload = () => {
                        // Setze den Ursprung der Transformation auf die Mitte des Bildes
                        ctx.save(); // Speichere den aktuellen Zustand des Kontextes
                        ctx.translate((cutOutImage.x - newImage.x) / scaleX + (cutOutImage.width / 2) / scaleX, 
                                      (cutOutImage.y - newImage.y) / scaleY + (cutOutImage.height / 2) / scaleY); // Verschiebe den Ursprung zur Mitte der Maske
                        ctx.rotate((cutOutImage.rotation || 0)); // Drehe die Maske
                        ctx.drawImage(maskImg, -cutOutImage.width / 2 / scaleX, -cutOutImage.height / 2 / scaleY, 
                                      cutOutImage.width / scaleX, cutOutImage.height / scaleY); // Zeichne die Maske relativ zum neuen Ursprung
                        ctx.restore(); // Stelle den vorherigen Zustand des Kontextes wieder her
                        resolve();
                    };
                    maskImg.onerror = reject;
                });
                // Füge die folgenden Zeilen hinzu, um die Werte in der Konsole anzuzeigen
                console.log(`translation: X: ${((cutOutImage.x - newImage.x) / scaleX + (cutOutImage.width / 2) / scaleX)}, Y: ${((cutOutImage.y - newImage.y) / scaleY + (cutOutImage.height / 2) / scaleY)}`);
                console.log(`cutOutImage: width: ${cutOutImage.width}, height: ${cutOutImage.height}, x: ${cutOutImage.x}, y: ${cutOutImage.y}`);

                // Berechnung der Position von maskUrl relativ zum Ursprung
                const maskX = cutOutImage.x; // X-Position von cutOutImage
                const maskY = cutOutImage.y; // Y-Position von cutOutImage

                // Protokolliere die berechnete Position
                console.log(`maskUrl Position: X: ${maskX}, Y: ${maskY}`);

                // Anwendung der Translation
                //ctx.translate(maskX, maskY); // Verschiebe den Ursprung zur Position von maskUrl                
            }

            const finalCombinedImageUrl = canvas.toDataURL('image/png');
            // Hochladen des kombinierten Maskenbildes an Cloudflare R2
            const blob = await (await fetch(finalCombinedImageUrl)).blob();
            try {
                const link = await uploadToR2(blob); // Hochladen an Cloudflare R2
                console.log('Uploaded Combined Mask URL:', link);
                setCombinedMaskUrl(link); // Setze die combinedMaskUrl
                return link; // Gib die URL zurück
            } catch (error) {
                console.error('Error uploading combined mask to Cloudflare:', error);
                setError('Failed to upload combined mask.'); // Benutzerbenachrichtigung
            }
        }
        return null; // Gebe null zurück, wenn kein Bild vorhanden ist
    };
    

    // Funktion zum Kombinieren von zwei Bildern
    // const combineImages = async (baseImageUrl, overlayImageUrl) => {
    //     const canvas = document.createElement('canvas');
    //     const ctx = canvas.getContext('2d');

    //     const baseImage = new Image();
    //     const overlayImage = new Image();

    //     // Setze das crossOrigin-Attribut für beide Bilder
    //     baseImage.crossOrigin = 'Anonymous';
    //     overlayImage.crossOrigin = 'Anonymous';

    //     baseImage.src = baseImageUrl;
    //     overlayImage.src = overlayImageUrl;

    //     await Promise.all([
    //         new Promise((resolve) => { baseImage.onload = resolve; }),
    //         new Promise((resolve) => { overlayImage.onload = resolve; }),
    //     ]);

    //     // Setze die Canvas-Größe
    //     canvas.width = baseImage.width;
    //     canvas.height = baseImage.height;

    //     // Zeichne das Basisbild
    //     ctx.drawImage(baseImage, 0, 0);

    //     // Finde die Position und Größe von newModelImage
    //     const newModelImagePosition = images.find(image => image.url === newModelImage);
    //     const newModelImageX = newModelImagePosition ? newModelImagePosition.x : 0; // Standardwert 0, falls nicht gefunden
    //     const newModelImageY = newModelImagePosition ? newModelImagePosition.y : 0; // Standardwert 0, falls nicht gefunden
    //     const newModelImageWidth = newModelImagePosition ? newModelImagePosition.width : 0; // Breite des newModelImage
    //     const newModelImageHeight = newModelImagePosition ? newModelImagePosition.height : 0; // Höhe des newModelImage

    //     // Finde die Position und Größe von imageUrlRb
    //     const imageRbPosition = images.find(image => image.url === imageUrlRb);
    //     console.log('imageRbPosition:', imageRbPosition); // Debugging-Log
    //     const imageRbX = imageRbPosition ? imageRbPosition.x : 0; // Standardwert 0, falls nicht gefunden
    //     const imageRbY = imageRbPosition ? imageRbPosition.y : 0; // Standardwert 0, falls nicht gefunden
    //     const imageRbWidth = imageRbPosition ? imageRbPosition.width : 0; // Breite des imageUrlRb
    //     const imageRbHeight = imageRbPosition ? imageRbPosition.height : 0; // Höhe des imageUrlRb

    //     // Optional: Ausgabe der Größen
    //     console.log(`newModelImage Size: Width: ${newModelImageWidth}, Height: ${newModelImageHeight}`);
    //     console.log(`imageUrlRb Size: Width: ${imageRbWidth}, Height: ${imageRbHeight}`);

    //     // Abrufen von imageWidthX, imageHeightY, imagePositionX und imagePositionY aus Firestore
    //     const { imageWidthX, imageHeightY, imagePositionX, imagePositionY } = await getImageDimensionsFromFirestore();

    //     // Berechne die neue Größe des Overlay-Bildes basierend auf der Skalierung
    //     const imageOverlayWidth = imageWidthX * 1024/ 2048;  // Verwende den abgerufenen Wert
    //     const imageOverlayHeight = imageHeightY * 1024/2048; // Verwende den abgerufenen Wert

    //     // Berechne die Position des Overlay-Bildes relativ zum Basisbild
    //     const overlayX = imagePositionX * 1024/2048; // Verwende den abgerufenen Wert
    //     const overlayY = imagePositionY * 1024/2048; // Verwende den abgerufenen Wert

    //     // Zeichne das Overlay-Bild an der berechneten Position mit Skalierung
    //     ctx.drawImage(overlayImage, overlayX, overlayY, imageOverlayWidth, imageOverlayHeight);
    //     console.log(`drawImage all infos: overlayX: ${overlayX}, overlayY: ${overlayY}, imageOverlayWidth: ${imageOverlayWidth}, imageOverlayHeight: ${imageOverlayHeight}`);
    //     // Gib die kombinierte Bild-URL zurück
    //     return canvas.toDataURL('image/png');
    // };

    // Funktion zum Abrufen von imageWidthX, imageHeightY, imagePositionX und imagePositionY aus Firestore
    // const getImageDimensionsFromFirestore = async () => {
    //     const docRef = doc(firestore, 'users', userId, 'images', docRefId); // Passe den Pfad an
    //     const docSnapshot = await getDoc(docRef);
    //     if (docSnapshot.exists()) {
    //         const data = docSnapshot.data();

    //         // Protokolliere die abgerufenen Werte
    //         console.log('Fetched data from Firestore:', data);
    //         console.log('imageWidthX:', data.imageWidthX || 946.5600000000001);
    //         console.log('imageHeightY:', data.imageHeightY || 811.4822317596565); // Hier den neuen Wert verwenden
    //         console.log('imagePositionX:', data.imagePositionX || 0);
    //         console.log('imagePositionY:', data.imagePositionY || 0);

    //         return {
    //             imageWidthX: data.imageWidthX || 946.5600000000001, // Standardwert, falls nicht vorhanden
    //             imageHeightY: data.imageHeightY || 811.4822317596565, // Standardwert, falls nicht vorhanden
    //             imagePositionX: data.imagePositionX || 0, // Standardwert, falls nicht vorhanden
    //             imagePositionY: data.imagePositionY || 0, // Standardwert, falls nicht vorhanden
    //         };
    //     }
    //     return {
    //         imageWidthX: 946.5600000000001, // Standardwert, falls das Dokument nicht existiert
    //         imageHeightY: 811.4822317596565, // Standardwert, falls das Dokument nicht existiert
    //         imagePositionX: 0, // Standardwert, falls das Dokument nicht existiert
    //         imagePositionY: 0, // Standardwert, falls das Dokument nicht existiert
    //     };
    // };

    return (
        <div>
            <div
                ref={coordinateSystemRef} // Referenz für das Koordinatensystem
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '20px',
                    width: '100%',
                    height: '400px',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: '#f9f9f9',
                }}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            >
                {images.map((image, index) => (
                    <div key={index} style={{ position: 'absolute', left: image.x, top: image.y }}>
                        <img
                            src={image.url}
                            alt={`Dropped ${index}`}
                            onMouseDown={(e) => handleMouseDown(index, e)} // Bild anklicken, um es zu ziehen
                            style={{
                                width: image.width,
                                height: image.height,
                                cursor: 'move',
                                transform: image.url === imageUrlRb ? `rotate(${image.rotation || 0}rad)` : 'none', // Wende die Rotation nur auf imageUrlRb an
                                transformOrigin: 'center', // Setze den Ursprung der Transformation auf die Mitte
                            }}
                        />
                        {/* Resize Handle */}
                        <div
                            onMouseDown={(e) => handleResizeMouseDown(index, e)} // Resize Handle anklicken
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                width: '30px', // Breite des Handles erhöhen
                                height: '30px', // Höhe des Handles erhöhen
                                backgroundColor: 'red', // Hintergrundfarbe
                                cursor: 'nwse-resize',
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%" // Füllt die Breite des Containers
                                height="100%" // Füllt die Höhe des Containers
                                viewBox="0 0 200 200" // Setze den ViewBox entsprechend
                                fill="none" // Keine Füllfarbe für den Hintergrund
                            >
                                <path d="M173.93,92.798c-4.971,0-9,4.029-9,9v50.404L30.728,18h50.404c4.971,0,9-4.029,9-9s-4.029-9-9-9H9C4.03,0,0,4.029,0,9
                                    v72.132c0,4.971,4.029,9,9,9s9-4.029,9-9V30.729l134.202,134.202h-50.404c-4.971,0-9,4.029-9,9s4.029,9,9,9h72.132
                                    c4.971,0,9-4.029,9-9v-72.132C182.93,96.828,178.901,92.798,173.93,92.798z" fill="#000000"/>
                            </svg>
                        </div>
                        {image.url === imageUrlRb && (
                            <div
                                onMouseDown={(e) => handleResizeMouseDown(index, e)} // Resize Handle anklicken
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: 0,
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                    cursor: 'nwse-resize',
                                }}
                            />
                        )}
                        {/* Rotate Handle */}
                        {image.url === imageUrlRb && ( // Zeige das Dreh-Handle nur für imageUrlRb an
                            <div
                                onMouseDown={(e) => handleRotateMouseDown(index, e)} // Rotate Handle anklicken
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    width: '30px', // Breite des Handles
                                    height: '30px', // Höhe des Handles
                                    backgroundColor: 'blue', // Hintergrundfarbe
                                    borderRadius: '50%', // Runde Form
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20" // Breite des Symbols
                                    height="20" // Höhe des Symbols
                                    viewBox="0 0 20 20" // Setze den ViewBox entsprechend
                                    fill="#FF0000" // Rot
                                >
                                    <path d="M10 7L9 6L11.2929 3.70711L10.8013 3.21553C10.023 2.43724 8.96744 2 7.86677 2C4.63903 2 2 4.68015 2 7.93274C2 11.2589 4.69868 14 8 14C9.53708 14 11.0709 13.4144 12.2426 12.2426L13.6569 13.6569C12.095 15.2188 10.0458 16 8 16C3.56933 16 0 12.3385 0 7.93274C0 3.60052 3.50968 0 7.86677 0C9.49787 0 11.0622 0.647954 12.2155 1.80132L12.7071 2.29289L15 0L16 1V7H10Z" fill="#000000"/>
                                </svg>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            
            {/* Anzeige der Positionen, Größen und Rotationen unter dem Koordinatensystem */}
            <div style={{ marginTop: '20px' }}>
                <h4>Image Positions, Sizes, and Rotations:</h4>
                {images.map((image, index) => (
                    <div key={index}>
                        <p>
                            {`Image ${index + 1}: Position (X: ${image.x}, Y: ${image.y}), Size (Width: ${image.width}, Height: ${image.height}), Rotation: ${image.rotation || 0} rad`}
                        </p>
                    </div>
                ))}
            </div>

            <button onClick={combineAndUploadImages} style={{ marginTop: '20px' }}>
                Combine and Upload Images
            </button>
                
             {/* Anzeige des verarbeiteten Bildes */}
             {processedImageUrl && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Processed Image:</h3>
                    <img
                        src={processedImageUrl}
                        alt="Processed"
                        style={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto',
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default CoordinateSystem;
